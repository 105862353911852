import {
  SoundOutlined,
  AudioOutlined,
  TeamOutlined,
  PlusOutlined,
  UserOutlined,
  DashboardOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const navigationConfig = (user) => {
  let menu = []

  const patientMenu =
  {
    key: `patients`,
    path: `patients`,
    title: 'Patients',
    icon: TeamOutlined,
    submenu: [
      {
        key: `${APP_PREFIX_PATH}/patients`,
        path: `${APP_PREFIX_PATH}/patients`,
        title: 'Patients',
        icon: TeamOutlined,
        submenu: []
      }
    ]
  }
  const voiceMenu =
  {
    key: `Voices`,
    title: 'Voices',
    icon: SoundOutlined,
    submenu: [
      {
        key: `${APP_PREFIX_PATH}/voices`,
        path: `${APP_PREFIX_PATH}/voices`,
        title: 'Voices',
        icon: SoundOutlined,
        submenu: []
      }
    ]
  }

  if (user && user.role !== 'trial') {
    voiceMenu.submenu.push({
      key: `${APP_PREFIX_PATH}/addvoice`,
      path: `${APP_PREFIX_PATH}/addvoice`,
      title: 'Train voice',
      icon: AudioOutlined,
      submenu: []
    })
    patientMenu.submenu.push({
      key: `${APP_PREFIX_PATH}/addpatient`,
      path: `${APP_PREFIX_PATH}/addpatient`,
      title: 'Create patient',
      icon: PlusOutlined,
      submenu: []
    })
  }


  const adminMenu =
  {
    key: `doctors`,
    path: `doctors`,
    title: 'Doctors',
    icon: TeamOutlined,
    submenu: [
      {
        key: `${APP_PREFIX_PATH}/doctors`,
        path: `${APP_PREFIX_PATH}/doctors`,
        title: 'Doctors',
        icon: UserOutlined,
        submenu: []
      },
      {
        key: `${APP_PREFIX_PATH}/adddoctor`,
        path: `${APP_PREFIX_PATH}/adddoctor`,
        title: 'Create doctor',
        icon: PlusOutlined,
        submenu: []
      },
    ]
  }

  const statistics = {
    key: `statistics`,
    path: `statistics`,
    title: 'Statistics',
    icon: DashboardOutlined,
    submenu: [
      {
        key: `${APP_PREFIX_PATH}/similarity`,
        path: `${APP_PREFIX_PATH}/similarity`,
        title: 'Similarity',
        icon: DashboardOutlined,
        submenu: []
      },
      {
        key: `${APP_PREFIX_PATH}/feedback`,
        path: `${APP_PREFIX_PATH}/feedback`,
        title: 'Feedback',
        icon: DashboardOutlined,
        submenu: []
      },
    ]
  }

  menu.push(patientMenu)
  menu.push(voiceMenu)
  if (user?.role === 'admin') {
    menu.push(adminMenu)
    menu.push(statistics)
  }

  return menu
}

export default navigationConfig;
