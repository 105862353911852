import React, { lazy, Suspense, useEffect, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import { UserContext } from 'contexts/UserContext';
import { useHistory } from "react-router";

export const AppViews = () => {
  const { user } = useContext(UserContext)
  const history = useHistory()

  useEffect(() => {
    if (!user) history.push(AUTH_PREFIX_PATH + '/login');// eslint-disable-next-line
  }, [])

  if (!user) {
    return (<Loading cover='content' />)
  }

  return (
    <Suspense fallback={<Loading cover="content" />}>
      {user && user.firstLogin ?
        <Switch>
          <Route path={`${APP_PREFIX_PATH}/firstpassword`} component={lazy(() => import(`./firstPassword`))} />
          <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/firstpassword`} />
        </Switch>
        :
        <Switch>
          <Route path={`${APP_PREFIX_PATH}/patients`} component={lazy(() => import(`./patients`))} />
          <Route path={`${APP_PREFIX_PATH}/voices`} component={lazy(() => import(`./voices`))} />
          <Route path={`${APP_PREFIX_PATH}/changepassword`} component={lazy(() => import(`./passwordChange`))} />
          <Route path={`${APP_PREFIX_PATH}/addpatient`} component={lazy(() => import(`./addPatient`))} />
          <Route path={`${APP_PREFIX_PATH}/editpatient/:patientid`} component={lazy(() => import(`./editPatient`))} />
          <Route exact path={`${APP_PREFIX_PATH}/sessions/:patientid`} component={lazy(() => import(`./sessions`))} />
          <Route path={`${APP_PREFIX_PATH}/addvoice`} component={lazy(() => import(`./addVoice`))} />
          <Route exact path={`${APP_PREFIX_PATH}/session/:patientId/:sessionId`} component={lazy(() => import(`./session`))} />
          {user.role === 'admin' &&
            <>
              <Route path={`${APP_PREFIX_PATH}/doctors`} component={lazy(() => import(`./users`))} />
              <Route path={`${APP_PREFIX_PATH}/adddoctor`} component={lazy(() => import(`./addUser`))} />
              <Route path={`${APP_PREFIX_PATH}/editdoctor/:userId`} component={lazy(() => import(`./editUser`))} />
              <Route path={`${APP_PREFIX_PATH}/changepassword`} component={lazy(() => import(`./passwordChange`))} />
              <Route path={`${APP_PREFIX_PATH}/similarity`} component={lazy(() => import(`./statistics/similarity`))} />
              <Route path={`${APP_PREFIX_PATH}/feedback`} component={lazy(() => import(`./statistics/feedback`))} />
            </>
          }
          <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/patients`} />
        </Switch>
      }
    </Suspense>
  )
}

export default React.memo(AppViews);